/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { EnvironmentLoader as environmentLoaderPromise } from './app/_environments/_environment.loader';

import { AppModule } from './app/app.module';
import { environment } from './app/_environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';

// Load env config dynamic
environmentLoaderPromise.then((env) => {
  if (env.production) {
    enableProdMode();
  }

  // Load env
  environment.envType = env.envType;
  environment.env = env.env;
  environment.production = env.production;
  environment.debug = env.debug;
  environment.rootPath = env.rootPath;
  environment.webSocketProtocol = env.webSocketProtocol;
  environment.webServiceProtocol = env.webServiceProtocol;
  environment.webServiceDomain = env.webServiceDomain;
  environment.webServicePort = env.webServicePort;
  environment.webServiceContextPath = env.webServiceContextPath;

  // Registering Syncfusion license key
//   registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZcc3VQQmBeVEZyX0E=');
  registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZcc3VQQ2dcVEB/X0Y=');

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
